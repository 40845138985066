<template>
  <section>
    <p class="text-h6 secondary--text">Seguimiento de reporte</p>
    <v-row>
      <v-col cols="12" sm="12" md="3">
        <p class="font-weight-medium primary--text mb-0">
          Nombre del proveedor:
        </p>
        <p class="text-subtitle-1 font-weight-bold primary--text">
          {{ reporteDetail?.proveedor.nombre }}
        </p>
        <p class="font-weight-medium primary--text mb-0">Nombre comercial:</p>
        <p class="text-subtitle-1 font-weight-bold primary--text">
          {{ reporteDetail?.proveedor.nombre_comercial }}
        </p>
        <v-img
          v-if="providerLogo"
          max-height="242"
          max-width="242"
          :src="providerLogo"
        />
        <v-icon v-else color="primary" size="232px"> mdi-image-outline </v-icon>
      </v-col>
      <v-col cols="12" sm="12" md="9">
        <section>
          <v-row>
            <v-col>
              <v-textarea
                label="Motivo de reporte *"
                outlined
                :value="reporteDetail?.motivo"
                readonly
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-btn text color="secondary" @click="descargarDocumento()">
                <v-icon color="secondary">mdi-download</v-icon>
                Descargar documento</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="isSancionar">
            <v-col cols="12">
              <v-select
                ref="select_desc"
                class="custom-select"
                label="Sanción a aplicar *"
                outlined
                clearable
                :value="newSancion.id_descripcion_sancion"
                item-value="id"
                return-object
                :items="tipos_sanciones"
                @blur="$v.newSancion.id_descripcion_sancion.$touch()"
                @change="setYearSancion"
                @click:clear="newSancion.id_descripcion_sancion = null"
              >
                <template #item="{ item, on, attrs }">
                  <div
                    class="d-flex flex-column"
                    style="width: -webkit-fill-available"
                  >
                    <div class="cerrado">
                      <p class="mb-0 mx-2">{{ item.nombre }}</p>
                    </div>
                    <v-list-item
                      v-for="desc in item.descripciones"
                      :key="desc.id"
                      v-on="on"
                      v-bind="attrs"
                      class="mx-4"
                      color="blueGrayMinsal"
                      :input-value="
                        desc.id === newSancion?.id_descripcion_sancion
                      "
                    >
                      <v-list-item-title
                        @click="setDescripcionSancion(desc.id)"
                        >{{ desc.nombre }}</v-list-item-title
                      >
                    </v-list-item>
                  </div>
                </template>
                <template #selection="{ item }">
                  {{
                    item.descripciones.find(
                      (target) =>
                        target.id === newSancion?.id_descripcion_sancion
                    )?.nombre
                  }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row v-if="isSancionar">
            <v-col cols="4">
              <v-text-field
                type="number"
                label="Años por sanción *"
                outlined
                min="1"
                max="5"
                @keyup="validYears"
                v-model="selectedYear"
              />
            </v-col>
            <v-col cols="4">
              <vc-date-picker
                mode="dateTime"
                locale="es"
                :popover="config_calendar"
                v-model="newSancion.fecha_inicio"
              >
                <template v-slot="{ inputEvents }">
                  <v-text-field
                    class="border focus:outline-none focus:border-blue-300"
                    label="Fecha y hora de inicio *"
                    readonly
                    prepend-inner-icon="mdi-calendar"
                    v-on="inputEvents"
                    outlined
                    :value="formattedFechaInicio"
                    @blur="$v.newSancion.fecha_inicio.$touch()"
                  />
                </template>
              </vc-date-picker>
            </v-col>
            <v-col cols="4">
              <vc-date-picker
                mode="dateTime"
                locale="es"
                :popover="config_calendar"
                :min-date="newSancion.fecha_inicio"
                v-model="newSancion.fecha_fin"
              >
                <template v-slot="{ inputEvents }">
                  <v-text-field
                    class="border focus:outline-none focus:border-blue-300"
                    label="Fecha y hora de fin *"
                    disabled
                    prepend-inner-icon="mdi-calendar"
                    v-on="inputEvents"
                    outlined
                    :value="formattedFechaFin"
                    @blur="$v.newSancion.fecha_fin.$touch()"
                  />
                </template>
              </vc-date-picker>
            </v-col>
          </v-row>
          <v-row v-if="isSancionar">
            <v-col cols="12">
              <v-textarea
                label="Motivo de sanción *"
                outlined
                v-model="newSancion.observaciones"
                @input="$v.newSancion.observaciones.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="isSancionar">
            <v-col cols="12">
              <v-file-input
                outlined
                label="Documento adjunto *"
                prepend-icon=""
                append-icon="mdi-paperclip"
                accept=".pdf"
                v-model="newSancion.documento"
                @blur="$v.newSancion.documento.$touch()"
              ></v-file-input>
            </v-col>
          </v-row>
        </section>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-btn text color="secondary" class="mr-4" @click="regresar()"
        >Regresar</v-btn
      >
      <v-btn
        outlined
        color="secondary"
        v-if="!isSancionar && reporteDetail.estado_sancion.nombre === 'Pendiente'"
        class="mr-4"
        @click="abrirRechazarReporte()"
        >Rechazar reporte</v-btn
      >
      <v-btn dark color="red" v-if="!isSancionar" @click="sancionar()"
        >Sancionar</v-btn
      >
      <!-- <v-btn outlined color="secondary" v-if="isSancionar" class="mr-4"
        >Guardar cambios</v-btn
      > -->
      
      <v-btn color="secondary" dark v-if="isSancionar === true" @click="guardarSancion()">
        Emitir sanción
      </v-btn>
    </v-row>

    <confirmation-dialog-component
      :show="rechazarReporte"
      btnConfirmar="Aceptar"
      title="¿Desea rechazar este reporte de sanción?"
      message="Esta acción hace que el estado del reporte de sanción sea rechazado y los cambios no serán reversibles"
      @close="rechazarReporte = false"
      @confirm="rechazarReporteEstado()"
    ></confirmation-dialog-component>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import moment from "moment";
export default {
  components: { ConfirmationDialogComponent },
  data: () => ({
    motivo: null,
    id_reporte_sancion: null,
    docuento: null,
    rechazarReporte: false,
    isSancionar: false,
    fecha_actual: moment().format("YYYY/MM/DD hh:mm"),
    config_calendar: {
      visibility: "click",
    },
    tipos_sanciones: [],
    newSancion: {
      id_descripcion_sancion: null,
      id_model: null,
      documento: null,
      observaciones: null,
      fecha_inicio: null,
      fecha_fin: null,
      id_proceso_compra: null,
      id_forma_contratacion: null,
    },
    selectedYear: 0,
    sancionSelected: {},
  }),
  validations: {
    newSancion: {
      id_descripcion_sancion: { required },
      documento: { required },
      observaciones: { required },
      fecha_inicio: { required },
      fecha_fin: { required },
    },
  },
  watch: {
    "newSancion.fecha_inicio"() {
      this.setFechaFin();
    },
    sancionSelected() {
      if (Object.keys(this.sancionSelected).length > 0) {
        Object.keys(this.sancionSelected).forEach((key) => {
          this.newSancion[key] = this.sancionSelected[key];
        });

        let start = moment(this.sancionSelected.fecha_inicio);
        let end = moment(this.sancionSelected.fecha_fin);
        let years = end.diff(start, "years");
        this.setYearSancion({ anios: years });
      } else {
        this.setYearSancion({ anios: 0 });
      }
    },
  },
  computed: {
    ...mapState("reporteProveedor", ["reporteDetail", "contrato"]),
    ...mapState("proveedores", ["providerLogo"]),
    readOnlySancion() {
      return Object.keys(this.sancionSelected).length > 0;
    },
    descripcionSancionErrors() {
      const errors = [];
      if (!this.$v.newSancion.id_descripcion_sancion.$dirty) return errors;
      !this.$v.newSancion.id_descripcion_sancion.required &&
        errors.push("Sanción a aplicar es obligatorio");
      return errors;
    },
    fechaInicioErrors() {
      const errors = [];
      if (!this.$v.newSancion.fecha_inicio.$dirty) return errors;
      !this.$v.newSancion.fecha_inicio.required &&
        errors.push("Fecha y hora de inicio es obligatorio");
      return errors;
    },
    fechaFinErrors() {
      const errors = [];
      if (!this.$v.newSancion.fecha_fin.$dirty) return errors;
      !this.$v.newSancion.fecha_fin.required &&
        errors.push("Fecha y hora de fin es obligatorio");
      return errors;
    },
    observacionesErrors() {
      const errors = [];
      if (!this.$v.newSancion.observaciones.$dirty) return errors;
      !this.$v.newSancion.observaciones.required &&
        errors.push("Motivo de sanción es obligatorio");
      return errors;
    },
    documentoErrors() {
      const errors = [];
      if (!this.$v.newSancion.documento.$dirty) return errors;
      !this.$v.newSancion.documento.required &&
        errors.push("Documento adjunto es obligatorio");
      return errors;
    },
    formattedFechaInicio() {
      return this.newSancion.fecha_inicio
        ? moment(this.newSancion.fecha_inicio).format("DD/MM/YYYY hh:mm A")
        : "";
    },
    formattedFechaFin() {
      return this.newSancion.fecha_fin
        ? moment(this.newSancion.fecha_fin).format("DD/MM/YYYY hh:mm A")
        : "";
    },
  },
  methods: {
    ...mapActions("reporteProveedor", ["getReporteDetail"]),
    ...mapActions("proveedores", ["getProviderLogo"]),
    validYears() {
      if (Number(this.selectedYear) < 1 || Number(this.selectedYear) > 5)
        this.selectedYear = "";
    },
    formClean() {
      this.$refs.select_desc.reset();
      Object.keys(this.newSancion).forEach((key) => {
        this.newSancion[key] = null;
      });
      this.$v.$reset();
    },
    setFechaFin() {
      this.newSancion.fecha_fin = moment(this.newSancion.fecha_inicio).add(
        this.selectedYear,
        "Y"
      );
    },
    setDescripcionSancion(id) {
      this.newSancion.id_descripcion_sancion = id;
    },
    setYearSancion(data) {
      this.selectedYear = data?.anios;
    },
    async getTiposSanciones() {
      const { status, data } =
        await this.services.ContratoService.getTiposSanciones();
      if (status === 200) {
        this.tipos_sanciones = data;
      }
    },
    async setData() {
      this.id_reporte_sancion = Number(this.$route.params.id_reporte_sancion);

      await this.getReporteDetail(this.id_reporte_sancion);
    },
    async descargarDocumento() {

      const response = await this.services.Sanciones.descargarArchivo({
        ruta: this.reporteDetail.documento,
      }).catch(() => {

      });
      if (response.status == 200) {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.reporteDetail.documento;
        link.click();

      }
    },
    sancionar() {

      this.isSancionar = true;
      this.getTiposSanciones();

    },
    async rechazarReporteEstado() {

      const { status } = await this.services.Sanciones.putEstadoReporte(
        this.id_reporte_sancion,
        {
          id_estado_reporte: 3,
        }
      ).catch(() => {

      });
      if (status === 204) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "El reporte de sanción fue rechazado",
        });
        this.$router.push({
          name: "reporte-proveedor",
        });
      }
      this.rechazarReporte = false;

    },
    abrirRechazarReporte() {
      this.rechazarReporte = true;
    },
    async guardarSancion() {
      this.$v.$touch();
      if (!this.$v.$invalid) {

        this.newSancion.id_model = this.reporteDetail.contrado_orden.id;

        this.newSancion.fecha_inicio = moment(
          this.newSancion.fecha_inicio
        ).format("YYYY/MM/DD hh:mm:ss");
        this.newSancion.fecha_fin = moment(this.newSancion.fecha_fin).format(
          "YYYY/MM/DD hh:mm:ss"
        );
        let formData = new FormData();
        Object.keys(this.newSancion).forEach((key) => {
          if (this.newSancion[key]) formData.append(key, this.newSancion[key]);
        });
        formData.append("years", this.selectedYear);

        const { status } = await this.services.ContratoService.postSanciones(
          this.reporteDetail.proveedor.id,
          formData
        );
        if (status === 201) {
          this.temporalAlert({
            show: true,
            message: "Sanción registrada",
            type: "success",
          });
          const { status } = await this.services.Sanciones.putEstadoReporte(
            this.id_reporte_sancion,
            {
              id_estado_reporte: 2,
            }
          );
          if (status === 204) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "El reporte de sanción fue aprobada",
            });
          }
            this.regresar();
        }
      }
    },
    regresar() {
      this.$router.back();
    },
  },
  async created() {
    this.setData();
    await this.getProviderLogo(this.reporteDetail.proveedor.id);
  },
};
</script>

<style></style>
